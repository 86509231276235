import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from '@ps-frontend/psp/not-found/feature';

const routes: Routes = [
  {
    path: ':lang',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('libs/psp/home/feature/src/lib/psp-home-feature.module').then(
            (module) => module.PspHomeFeatureModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import(
            'libs/psp/search/feature/src/lib/psp-search-feature.module'
          ).then((module) => module.PspSearchFeatureModule),
      },
      {
        path: 'service/:code',
        loadChildren: () =>
          import(
            'libs/psp/public-service/feature/src/lib/psp-public-service-feature.module'
          ).then((module) => module.PspPublicServiceFeatureModule),
      },
      {
        path: 'organization/:code',
        loadChildren: () =>
          import(
            'libs/psp/organization/feature/src/lib/psp-organization-feature.module'
          ).then((module) => module.PspOrganizationFeatureModule),
      },
      {
        path: 'events',
        loadChildren: () =>
          import(
            'libs/psp/life-events/life-events.module'
          ).then((module) => module.LifeEventsModule),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import(
            'libs/psp/catalog/feature/src/lib/psp-catalog-feature.module'
          ).then((module) => module.PspCatalogFeatureModule),
      },
      {
        path: 'event/:code',
        loadChildren: () =>
          import(
            'libs/psp/public-event/feature/src/lib/psp-public-event-feature.module'
          ).then((module) => module.PspPublicEventFeatureModule),
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import(
            'libs/psp/organizations/feature/src/lib/psp-organizations-feature.module'
          ).then((module) => module.PspOrganizationsFeatureModule),
      },
      {
        path: 'cups',
        loadChildren: () =>
          import(
            'libs/psp/cups/feature/src/lib/psp-cups-feature.module'
          ).then((module) => module.PspCupsFeatureModule),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import(
            'libs/psp/terms-and-conditions/feature/src/lib/psp-terms-and-conditions-feature.module'
          ).then((module) => module.PspTermsAndConditionsFeatureModule),
      },
      {
        path: 'support',
        loadChildren: () =>
          import(
            'libs/psp/support/feature/src/lib/psp-support-feature.module'
          ).then((module) => module.PspSupportFeatureModule),
      },
    ],
  },


  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      title: 'pages.notFoundPageTitle',
      description: 'seo.notFoundPage.description',
      ogTitle: 'pages.notFoundPageTitle',
      ogDescription: 'seo.notFoundPage.description',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
